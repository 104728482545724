.result_body{
    width: 100%;
    .result_container{
        width: 1200px;
        margin: 0 auto;
    }
}
.table_body{
    padding-bottom: 30px;
}
.pageTitle{
    color: #253858;
    padding: 50px 0 20px 0;
}

@media screen and (max-width: 1200px) {
    .result_container{
        width: 690px !important;
        margin: 0 auto;
    }
}

@media screen and (max-width: 750px) {
    h1{
        font-size: 27px;
      }
      .result_container{
        width: 350px !important;
        margin: 0 auto;
    }
}
@media screen and (max-width: 350px) {
  
  h1{
    font-size: 22px;
  }
  .result_container{
    width: 300px !important;
    margin: 0 auto;
}
}