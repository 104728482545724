.writeExam_body {
  width: 100%;
  // height: 100vh;
  .header_text {
    margin: 0 auto;
    width: 300px;
    font-size: 15px;
    color: #253858;
    padding-top: 50px;
    h1 {
      text-align: center;
    }
  }
}
.question_body {
  display: flex;
  width: 1200px;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 50px;
  margin-top: 10px;
  .question_count {
    width: 100px;
    background-color: red;
  }
}
.question_option {
  width: 650px;
  padding: 30px 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  .option_body {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    .option {
      width: 250px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      background-color: #6db0fcfe;
      border: 2px solid transparent;
      border-radius: 7px;
      padding: 7px 7px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      color: white;
    }
    .Selectedoption {
      width: 250px;
      // height: 50px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      border: 2px solid #163c7f;
      border-radius: 7px;
      padding: 7px 7px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      color: #264e93;
    }
  }
  .selectedOption {
    border: 1px solid red;
    height: 50px;
  }
}
.next_previous_btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  button {
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    transition: all 0.3s ease;
    width: 120px;
    height: 30px;
    &:hover {
      cursor: pointer;
    }
  }
  .next_btn {
    color: white;
    border: 2px solid transparent;
    cursor: pointer;
  }
  .submit_btn {
    background-color: #04aa6d;
    color: white;
    border: none;
    transition: all 0.3s ease;
    &:hover {
      background-color: #46f8b7;
      cursor: pointer;
    }
  }
}
.result_body {
  width: 100%;
  padding-bottom: 50px;
  .result_container {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    /* From https://css.glass */
    background: rgba(51, 150, 227, 0.43);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(51, 150, 227, 0.75);
    margin-top: 20px;
    .result_items {
      width: 50%;
      h1 {
        background: #7a25cf;
        background: repeating-linear-gradient(
          to right,
          #7a25cf 0%,
          #44cfaf 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        color: rgb(0, 0, 0);
        font-size: 18px;
        margin-top: 10px;
      }
    }
    .lottie_animation {
      width: 40%;
      height: 300px;
    }
  }
}

.review_contanier {
  width: 1000px;
  margin: 0 auto;
  // background-color: red;
  padding: 0px 50px 20px 50px;
  .correct_options_review {
    /* From https://css.glass */
    background: rgba(26, 223, 10, 0.38);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.1px);
    -webkit-backdrop-filter: blur(5.1px);
    border: 1px solid rgba(26, 223, 10, 0.52);
    padding: 10px 15px;
    margin-bottom: 10px;
    h1 {
      font-size: 20px;
      // color: rgb(63, 63, 63);
    }
    p {
      font-size: 16px;
      color: rgb(63, 63, 63);
      margin-left: 25px;
      margin-top: 5px;
    }
  }
  .wrong_options_review {
    /* From https://css.glass */
    background: rgba(223, 10, 10, 0.34);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(223, 10, 10, 0.41);
    padding: 10px 15px;
    margin-bottom: 10px;
    h1 {
      font-size: 20px;
      color: rgb(63, 63, 63);
    }
    p {
      font-size: 16px;
      color: rgb(63, 63, 63);
      margin-left: 25px;
      margin-top: 5px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .Instructions_body {
    width: 400px !important;
  }
  .question_body {
    width: 690px !important;
  }
  .result_container {
    width: 690px !important;
  }
  .review_contanier {
    width: 690px !important;
  }
}

.result_btns {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .respet_btn {
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    font-weight: 500;
    align-items: center;
    gap: 5px;
    padding: 5px 5px;
    cursor: pointer;
  }
  .review_btn {
    background-color: transparent;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 5px;
    cursor: pointer;
  }
  .close_btn {
    background-color: black;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    padding: 8px 10px;
    cursor: pointer;
  }
}
@media screen and (max-width: 750px) {
  .review_contanier {
    width: 350px !important;
    padding: 20px 10px;
    .correct_options_review {
      padding: 10px 10px;
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
    .wrong_options_review {
      padding: 10px 10px;
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .Instructions_body {
    width: 310px !important;
  }
  .question_body {
    width: 350px !important;
  }

  .option {
    width: 290px !important;
  }
  .Selectedoption {
    width: 290px !important;
  }
  .result_container {
    width: 350px !important;
    .result_items {
      width: 100% !important;
    }
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
      text-align: center;
    }
    p {
      margin-left: 20px;
    }
    .result_btns {
      flex-direction: column;
      margin-left: 20px;
      button {
        width: 150px;
      }
    }
  }
  .lottie_animation {
    width: 100% !important;
    height: 300px;
  }
}
@media screen and (max-width: 350px) {
  .question_option{
    width: 300px ;
  }
    .review_contanier {
    width: 300px !important;
    padding: 20px 10px;
    .correct_options_review {
      padding: 10px 10px;
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
    .wrong_options_review {
      padding: 10px 10px;
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .Instructions_body {
    width: 310px !important;
  }
  .question_body {
    width: 280px !important;
  }

  .option {
    width: 260px !important;
  }
  .Selectedoption {
    width: 260px !important;
  }
  .next_previous_btns{
    button{
        width: 100px;
    }
  }
  .result_container {
    width: 300px !important;
    .result_items {
      width: 100% !important;
    }
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
      text-align: center;
    }
    p {
      margin-left: 20px;
    }
    .result_btns {
      flex-direction: column;
      margin-left: 20px;
      button {
        width: 150px;
      }
    }
  }
  .lottie_animation {
    width: 100% !important;
    height: 300px;
  }
}
.text_timer {
  display: flex;
  justify-content: space-between;
}
.timer {
  width: 60px;
  padding: 2px 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(51, 150, 227, 0.43);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(51, 150, 227, 0.75);
  border-radius: 5px;
  color: white;
  font-size: 18px;
}
