.loader_body::-webkit-scrollbar {
  display: none;
}
.loader_body{
    position: absolute;
    inset: 0;
    height: auto;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    min-height: 150vh;
    .lds_roller{
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds_roller div {
        animation: lds_roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
      }
      .lds_roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #ffffff;
        margin: -4px 0 0 -4px;
      }
      .lds_roller div:nth-child(1) {
        animation-delay: -0.036s;
      }
      .lds_roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
      }
      .lds_roller div:nth-child(2) {
        animation-delay: -0.072s;
      }
      .lds_roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
      }
      .lds_roller div:nth-child(3) {
        animation-delay: -0.108s;
      }
      .lds_roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
      }
      .lds_roller div:nth-child(4) {
        animation-delay: -0.144s;
      }
      .lds_roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
      }
      .lds_roller div:nth-child(5) {
        animation-delay: -0.18s;
      }
      .lds_roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
      }
      .lds_roller div:nth-child(6) {
        animation-delay: -0.216s;
      }
      .lds_roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
      }
      .lds_roller div:nth-child(7) {
        animation-delay: -0.252s;
      }
      .lds_roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
      }
      .lds_roller div:nth-child(8) {
        animation-delay: -0.288s;
      }
      .lds_roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
      }
      @keyframes lds_roller {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
}