.inp_name_body {
  padding-top: 10px;
  height: 90px;
  .require {
    color: red;
    font-size: 11px;
    margin-top: 4px;
  }
  .exam_name {
    color: #253858;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .inp_body {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 10px;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 0px 5px;
    gap: 8px;
    font-size: 20px;

    textarea {
      width: 100%;
      height: 35px;
      border: none;
      font-size: 14px;

      &::placeholder {
        font-size: 16px;
        font-style: italic;
        color: #253858;
      }

      &:focus {
        outline: none;
      }
    }
    input {
      width: 100%;
      height: 35px;
      border: none;
      font-size: 14px;

      &::placeholder {
        font-size: 16px;
        font-style: italic;
        color: #253858;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
.inp_name_body_about {
  padding-top: 10px;
  height: 470px;
  .require {
    color: red;
    font-size: 11px;
    margin-top: 4px;
  }
  .exam_name {
    color: #253858;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .inp_body {
    width: 100%;
    height: 400px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 10px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 0px 5px;
    gap: 8px;
    font-size: 20px;

    textarea {
      width: 100%;
      height: 35px;
      border: none;
      font-size: 14px;

      &::placeholder {
        font-size: 16px;
        font-style: italic;
        color: #253858;
      }

      &:focus {
        outline: none;
      }
    }
    input {
      width: 100%;
      height: 35px;
      border: none;
      font-size: 14px;

      &::placeholder {
        font-size: 16px;
        font-style: italic;
        color: #253858;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.option_body {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 70%;
  margin: 0 auto;

  .inp_name_body {
    padding-top: 10px;
    height: 80px;

    .require {
      color: red;
      font-size: 11px;
      // margin-top: 2px;
    }
    .exam_name {
      color: #253858;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 2px;
    }

    .inp_body {
      width: 120px;
      height: 40px;
      border: 1px solid rgb(230, 230, 230);
      border-radius: 10px;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding: 0px 5px;
      gap: 8px;
      font-size: 20px;

      input {
        width: 100%;
        height: 35px;
        border: none;
        font-size: 14px;

        &::placeholder {
          font-size: 13px;
          font-style: italic;
          color: #253858;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  .cancelBtn {
    margin-top: 20px;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s ease;
    background-color: #0067f1;
    &:hover {
      background-color: #468ce9;
      cursor: pointer;
    }
  }
  .addBtn {
    margin-top: 20px;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #5aac44;
    color: white;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s ease;
    margin-left: 20px;
    &:hover {
      background-color: #72b75f;
      cursor: pointer;
    }
  }
}
