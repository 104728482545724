@media screen and (max-width: 1200px) {
    
}

@media screen and (max-width: 750px) {
    h1{
        font-size: 27px;
      }
}
@media screen and (max-width: 350px) {
  
  h1{
    font-size: 22px;
  }
}