.container{
    max-width: 1200px;
    margin: 0 auto;
    .abouts1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      height: auto;
      width: 100%;
      div{
        width: 50%;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      p{
        width: 50%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        span{
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .abouts2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      height: auto;
      width: 100%;
      div{
        width: 50%;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      p{
        width: 50%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 0 10px;

        span{
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .abouts3{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      height: auto;
      width: 100%;
      padding-bottom: 30px;

      div{
        width: 50%;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      p{
        width: 50%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 0 10px;
        span{
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
}

.about_body{
    width: 100%;
    padding-top: 50px;
    .aboutTopBg{
        min-height: 306px;
        width: 100%;
        background-image: url('https://getwork.com/assets/img/hero-imgs/dark-blue-hero.svg');
        background-size: cover;
        background-position: center bottom;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        .topTextBody{
          width: 70%;
          padding: 0 10px;
          h2{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 48px;
            font-weight: 900;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
          p{
            text-align: center;
            font-size: 20px;
            color: rgb(255, 255, 255);

          }
          h3{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 24px;
            font-weight: 500;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
        }
    }
    .aboutTopBg2{
        min-height: 306px;
        width: 100%;
        background-image: url('https://getwork.com/assets/img/hero-imgs/dark-blue-hero.svg');
        background-size: cover;
        background-position: center bottom;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        margin-bottom: 3px;
        .topTextBody{
          width: 70%;
          padding: 0 10px;
          h2{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 48px;
            font-weight: 900;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
          p{
            text-align: center;
            font-size: 20px;
            color: rgb(255, 255, 255);

          }
          h3{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 24px;
            font-weight: 500;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
        }
    }
}

@media screen and (max-width: 750px) {
    .topTextBody{
        h2{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 35px !important;
            font-weight: 900;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
          p{
            text-align: center;
            font-size: 16px !important;
            color: rgb(255, 255, 255);

          }
          h3{
            font-size: 20px !important;
          }
    }
    .abouts1{
      flex-direction: column;
      div{
        width: 90% !important;
      }
      P{
        width: 90% !important;
      }
    }
    .abouts3{
      flex-direction: column;
      padding-top: 30px;
      div{
        width: 90% !important;
      }
      P{
        width: 90% !important;
      }
    }
    .abouts2{
      flex-direction: column-reverse;
      padding-top: 30px;
      div{
        width: 90% !important;
      }
      P{
        width: 90% !important;
      }

    }
  }