footer {
  .footer__container {
    width: 1200px;
    // height:210px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom:20px ;
    .footer_content {
      padding-top: 20px;
      display: flex;
      // align-items: center;
      gap: 30px;
      .footer_left {
        width: 300px;
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .footer_right {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 30px;
        p {
          color: #fff;
          text-transform: uppercase;
          text-decoration: none;
          letter-spacing: 0.15em;
          // padding-bottom: 5px;
          cursor: pointer;
          display: inline-block;
          // padding: 15px 0px;
          position: relative;
          &::after {
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            background: #fff;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
          }
          &:hover::after {
            cursor: pointer;
            width: 100%;
            left: 0;
          }
        }
        .light_text {
          color: white;
        }
        .dark_text {
          color: #264e93;
        }
      }
    }
    .footer__bottom {
      margin-top: 30px;

      p {
        padding-top: 20px;
        padding-bottom: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 110%;
        color: #cccccc;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .footer__bottom {
      // padding-bottom: 10px;
    }
    .footer__container {
      width: 690px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 750px) {
    .footer__bottom {
      padding-bottom: 40px;
    }
    .footer__bottom{
      // margin-bottom: 10px !important;
      padding-bottom: 10px;
    }
    .footer__container {
      width: 350px;
      margin: 0 auto;
    }
    .footer_content {
      flex-direction: column;
      .footer_right {
        flex-direction: column;
        align-items: flex-start !important;
        p {
          &:hover::after {
            cursor: pointer;
            width: 50% !important;
            left: 0;
          }
        }
      }
    }
  }
  // @media screen and (max-width: 750px) {


  //   .footer__container {
  //     width: 350px;
  //     margin: 0 auto;
  //   }
  //   .footer__bottom{
  //     // margin-bottom: 10px !important;
  //     padding-bottom: 10px;
  //   }
  //   .footer_content {
  //     flex-direction: column;
  //     .footer_right {
  //       flex-direction: column;
  //       p {
  //         &:hover::after {
  //           cursor: pointer;
  //           width: 50% !important;
  //           left: 0;
  //         }
  //       }
  //     }
  //   }
  // }
  @media screen and (max-width: 350px) {


    .footer__container {
      width: 300px;
      margin: 0 auto;
    }
    .footer_content {
      flex-direction: column;
      .footer_right {
        flex-direction: column;
        p {
          &:hover::after {
            cursor: pointer;
            width: 50% !important;
            left: 0;
          }
        }
      }
    }
  }
}
