.quizz_body{
    width: 100%;
    height: 100vh;
    background-color: rgb(244, 244, 244); 
}
.dark_quizz_body{
    background-color: #121721;
    height: 100vh;

}
.container{
   width: 1200px ;
   margin: 0 auto;
    .header_text{
        color: #253858;
        font-size: 14px;
        padding-top: 50px;
    }
    .darkHeader_text{
        // color: #dee1e6;
        color: #456cb0;

        // color:rgb(241, 241, 241);
        font-size: 14px;
        padding-top: 50px;
    }
    .card_body{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
        padding-bottom: 50px;
        
        .card{
            border-radius: 10px;
            width: 35%;
            height: 250px;
            // 
            padding: 10px 10px;
            // background-color: white;
            box-shadow: rgba(22, 117, 213, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            transition: all 0.3s ease;
            border: 1px  solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #456cb0;
            display: flex;
            flex-direction: column;
            p{
                width: 100%;
                font-size:40px;
                color: white;
                text-align: center;
            }
            &:hover{
                box-shadow: none;
                // border: 1px solid rgb(119, 119, 119);
                cursor: pointer;
            }
            div{
                width: 100%;
                display: flex;
                font-size: 100px;
            align-items: center;
            justify-content: center;
            }
            .card_logo{
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(47, 79, 198);
                border-radius: 15px;
                margin-right: 10px;
                img{
                    width: 20px;
                    height: 15px;
                }
            }
            h1{
                font-size: 16px;
                // margin-bottom: 10px;
                width: 200px !important;
            }
 
            .btn_body{
                width: 200px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    // border: 2px solid  #253858;
                    background-color: transparent;
                    padding: 5px 10px;
                    border-radius: 5px;
                    // color: #253858;
                    font-weight: 500;
                    font-size: 15px;
                    transition: all 0.3s ease;
                    &:hover{
                        background-color: #5aa2f5;
                        color: white !important;
                        border: 2px solid transparent;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.search_filter{
    width: 100%;
    .search{
        width: 400px;
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 5px;
        border-radius: 10px;
        gap: 7px;
        color: #253858;
        margin-top: 10px;
        input{
            height: 30px;
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 16px;
            color: #253858;

            &::placeholder{
                color: #253858;
                font-style: italic;
                font-size: 16px;
            }
            &:focus{
                outline: none;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .container{
        width: 690px;
        margin: 0 auto;
    }
    .header_text{
        width: 690px;
        font-size: 12px !important;
        // text-align: center;
        margin: 0 auto;
    }
    .card_body{
        display: flex;
        justify-content: center;
    }
    .search{
        // margin: 0 auto;
        width: 360px !important;
    }

}
@media screen and (max-width: 750px) {
    .container{
        width: 350px;
        margin: 0 auto;
    }
    .header_text{
        width: 350px;
        font-size: 9px !important;
        text-align: center;
    }
    .card_body{
        display: flex;
        justify-content: center;
    }
    .search{
        margin: 0 auto;
        width: 270px !important;
    }
    .card{
        flex-direction: column;
        width:270px !important;
        height: 270px !important;
        justify-content: flex-start;
        align-items: flex-start;
       p{
        padding: 0 !important;
       }
    }
  
}
@media screen and (max-width: 750px) {
    .container{
        width: 300px !important;
        margin: 0 auto;
    }
    .header_text{
        width: 300px;
        font-size: 9px !important;
        text-align: center;
    }
    .card_body{
        display: flex;
        justify-content: center;
    }
    .search{
        margin: 0 auto;
        width: 270px !important;
    }
    .card{
        flex-direction: column;
        width:270px !important;
        height: 270px !important;
        justify-content: flex-start;
        align-items: flex-start;
       p{
        padding: 0 !important;
       }
    }
  
}